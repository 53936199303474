export const socialIconList = [
  {
    name: 'youtube',
    prefix: 'fab', // fab prefix for brands, fas for solid icons
    color: '#FF0000',
    link: 'https://www.youtube.com/channel/UCBSifyNqLFNEzcILvwxRxYg',
  },
  {
    name: 'twitter',
    prefix: 'fab', 
    color: '#5DA9DD',
    link: 'https://twitter.com/maxcode_fr',
  },
  {
    name: 'github',
    prefix: 'fab',
    color: 'white',
    link: 'https://github.com/maximeadjigble',
  },
  // {
  //   name: 'discord',
  //   prefix: 'fab',
  //   color: '#6E85D2',
  //   link: '#',
  // },
  // Add further social links with the icon of choice and link here
  // check https://fontawesome.com/icons?d=gallery&s=brands for other brand icons
];
