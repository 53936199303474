import styled from '@emotion/styled';
import { css } from '@emotion/core';
import React from 'react';
import { Link } from 'gatsby';
import { flexCenter } from './../_shared/styled-mixins';

const buttonBase = css`
  ${flexCenter};
  text-decoration: none;
  font-size: 0.9rem;
  font-weight: 500;
  white-space: nowrap;
  position: relative;
  border: none;
  border-radius: 5px;
  padding: 0.4rem 0.8rem;
`;

const buttonDefaultStyle = css`
  color: var(--title-color) !important;
  background: var(--secondary-color);

  &:hover {
    color: var(--bg-content-color) !important;
  }
`;

export const StyledButtonLink = styled.a`
  ${buttonBase};
  ${buttonDefaultStyle};

  & > svg {
    fill: var(--bg-content-color);
    height: 0.8rem;
    margin-left: 0.25rem;
  }

  &:hover > svg {
    fill: var(--primary-color);
  }
`;

export const StyledButtonHeroLink = styled(Link)`
  ${buttonBase};
  color: var(--bg-content-color) !important;
  background-color: var(--title-color);

  &:hover {
    color: var(--primary-color) !important;
  }

  &:after {
    content: '';
    z-index: -1;
    border: 1px solid var(--title-color);
    border-radius: 5px;
    position: absolute;
    bottom: -3px;
    right: -3px;
    width: 100%;
    height: 100%;
    transition: all ease var(--transition-fast);
  }

  &:hover:after {
    border: 1px solid var(--primary-color);
    bottom: -5px;
    right: -5px;
  }

  & > svg {
    fill: var(--bg-content-color);
    height: 0.8rem;
    margin-left: 0.25rem;
  }

  &:hover > svg {
    fill: var(--primary-color);
  }
`;


export const StyledButton = styled.button`
    ${buttonBase};
    ${buttonDefaultStyle};
`;


export const ButtonHeroLink = ({ label, link , newTab = false }) => {
  return (
    <React.Fragment>
      {label && link && (
        <StyledButtonHeroLink to={link ? link : '#'} rel="noopener">
          {label}
        </StyledButtonHeroLink>
      )}
    </React.Fragment>
  );
};


export const ButtonLink = ({ label, link , newTab = false}) => {
  return (
    <React.Fragment>
      {label && link && (
        <StyledButtonLink href={link ? link : '#'} target={newTab? "_blank": "_self"} rel="noopener">
          {label}
        </StyledButtonLink>
      )}
    </React.Fragment>
  );
};

