export const indexMenuLinks = [
  {
    name: 'FORMATIONS',
    link: '/#formations',
    icon: 'video',
    prefix: 'fas',
    isButton: true,
  },
  {
    name: 'A PROPOS',
    link: '/#a-propos',
    icon: 'user-tie',
    prefix: 'fas',
  },
  // {
  //   name: 'ARTICLES',
  //   link: '/#articles',
  //   icon: 'feather-alt',
  //   prefix: 'fas',
  // },
  {
    name: 'CONTACT',
    link: '/#contact',
    icon: 'envelope',
    prefix: 'fas',
  },
];

export const blogMenuLinks = [
  {
    name: 'FORMATIONS',
    link: '/formations',
    icon: 'video',
    prefix: 'fas',
    isButton: true,
  },
  // {
  //   name: 'ARTICLES',
  //   link: '/articles',
  //   icon: 'feather-alt',
  // },
  // {
  //   name: 'TAGS',
  //   link: '/tags',
  //   icon: 'tags',
  // },
];
